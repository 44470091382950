<template>
  <aside class="navbar navbar-vertical navbar-expand-lg navbar-dark">
    <div class="container-fluid" v-loading="isMenuLoading">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark">
        <a href=".">
          <img src="@/assets/images/v-logo.png" width="210" alt="Tabler" class="navbar-brand-image">
        </a>
      </h1>
      <div class="navbar-nav flex-row d-lg-none">
        <div class="nav-item dropdown d-none d-md-flex me-3">
          <a href="#" class="nav-link px-0" data-bs-toggle="dropdown" tabindex="-1" aria-label="Show notifications">
            <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
            <span class="badge bg-red"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-card">
            <div class="card">
              <div class="card-body">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad amet consectetur exercitationem fugiat in ipsa ipsum, natus odio quidem quod repudiandae sapiente. Amet debitis et magni maxime necessitatibus ullam.
              </div>
            </div>
          </div>
        </div>
        <div class="nav-item dropdown">
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
            <span class="avatar avatar-sm" style="background-image: url(./static/avatars/000m.jpg)"></span>
            <div class="d-none d-xl-block ps-2">
              <div>Paweł Kuna</div>
              <div class="mt-1 small text-muted">UI Designer</div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <a href="#" class="dropdown-item">Set status</a>
            <a href="#" class="dropdown-item">Profile & account</a>
            <a href="#" class="dropdown-item">Feedback</a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">Settings</a>
            <a href="#" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbar-menu">
        <ul class="navbar-nav pt-lg-3">
          <sidebar-item
            v-for="(menuItem, i) in menuList"
            :key="i"
            :data="menuItem" />
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
// import PerfectScrollbar from 'perfect-scrollbar';
// import async from 'async';

const menu = (to, title, icon, child = [], el_icon = '') => ({
  to,
  title,
  icon,
  child,
  el_icon,
});

export default {
  name: 'Sidebar',
  metaInfo: {
    bodyAttrs: {
      class: ['sidebar-dark'],
    },
  },
  data() {
    return {
      isFolded: false,
      isMenuLoading: true,
      menuList: [
        menu('/dashboard', 'Dashboard', 'home'),
        menu('/vouchers', 'Vouchers', 'ticket-alt'),
        menu('/ppob', 'PPOB', 'shopping-bag'),
        menu('/merchants', 'Merchants', 'store'),
        // menu('/my-benefit', 'Mybenefit', 'gift'),
        menu('/merchants', 'General Merchant', 'store'),
        menu('/merchants-apps', 'Merchant Apps', 'warehouse', [
          menu('/merchants-apps', 'Merchant List'),
          menu('/merchants-apps/all-po', 'Pre-Order List'),
          menu('/merchants-apps/invoices', 'Invoices'),
        ]),
        menu('/rewards', 'Rewards', 'gem'),
        // menu('/academys', 'Academys', 'chalkboard-teacher'),
        menu('/missions', 'Missions', 'running'),
        menu('/users-app', 'User Apps', 'mobile-alt'),
        menu('/app-contents', 'App Content', 'layer-group', [
          menu('/app-contents/home', 'Home Content'),
        ]),
        menu('/companies', 'Companies', 'building', [
          menu('/companies', 'Companies'),
          menu('/submission', 'Submission'),
          menu('/companies/mybenefit-request', 'MyBenefit Request'),
          menu('/companies/ewa', 'EWA'),
        ]),
        menu('/broadcast', 'User Notifications', 'bullhorn'),
        menu('/disbursement', 'Disbursement', 'hand-holding-usd'),
        menu('/earned-wage', 'Early Wage Access', 'money-bill-wave', [
          menu('/earned-wage', 'EWA List'),
          menu('/earned-wage/report', 'EWA Report'),
        ]),
        menu('/promo', 'Promo', 'newspaper'),
        // menu('/refund-request', 'Refund Report', 'money-bill-wave'),
        menu('/transactions', 'Transactions', 'dollar-sign'),
        menu('/finance/transactions', 'Finance', 'chart-line', [
          // menu('/finance/transactions/mobile-pulsa', 'Mobile Pulsa'),
          // menu('/finance/transactions', 'All Report'),
          // menu('/finance/transactions/ultra-voucher', 'Ultra Voucher'),
          // menu('/finance/transactions/e2pay', 'E2Pay'),
          // menu('/finance/transactions/rajabiller', 'Raja Biller'),
          // menu('/UploadFile', 'Upload File'),
          menu('/finance/transactions', 'All Report'),
          menu('/finance/transactions/mobile-pulsa-voucher', 'Mobile Pulsa Voucher'),
          menu('/finance/transactions/mobile-pulsa-ppob', 'Mobile Pulsa PPOB'),
          menu('/finance/transactions/ultra-voucher', 'Ultra Voucher'),
          menu('/finance/transactions/grabgift', 'Grab Gift'),
          menu('/finance/transactions/familymart', 'FamilyMart'),
          menu('/finance/transactions/kenangangroup', 'Kenangan Group'),
          menu('/finance/transactions/e2pay', 'E2Pay'),
          menu('/finance/transactions/billfaz', 'Billfazz'),
          menu('/finance/transactions/qris', 'Qris'),
        ]),
         menu('/coin', 'Koin', 'coins'),
        // menu('/app-setting', 'App Setting', 'dollar-sign'),
        // menu('/roles', 'New Role', 'chart-line'),

        menu('/referral', 'Referral Program', 'user-friends', [
          menu('/referral/categories', 'Categories', 'money-bill-wave'),
          menu('/referral', 'Referral Program', 'money-bill-wave'),
          menu('/referral/claims', 'Referral Claims', 'money-bill-wave'),
        ]),
        menu('/refund', 'Refund', 'money-bill-wave', [
          menu('/refund', 'Refund', 'money-bill-wave'),
          menu('/refund-request', 'Refund Report', 'money-bill-wave'),
        ]),
        menu('/deposit', 'Deposit', 'chart-line', [
          menu('/deposit/history', 'History'),
          menu('/deposit/topup', 'Top Up'),
          menu('/deposit/adjustment', 'Adjustment'),
        ]),
        // menu('/global-setting', 'Global Setting', 'mobile-alt'),
        menu('/popup-banner', 'Popup Banner', 'ad'),
        menu('/gamification', 'Gamification', 'medal', [
          menu('/gamification/event', 'Event'),
          menu('/gamification/transaction', 'Transaction'),
          // menu('/refund', 'Participants'),
          // menu('/gamification', 'Transaction'),
        ]),
      ],
      // perfectScroll: [],
      labelList: [],
    };
  },
  mounted() {
    // this.setSidebar();
    // if (document.querySelectorAll('.sidebar .sidebar-body').length) {
    //   this.perfectScroll.push(new PerfectScrollbar('.sidebar-body'));
    // }
    // if (document.querySelectorAll('.content-nav-wrapper').length) {
    //   this.perfectScroll.push(new PerfectScrollbar('.content-nav-wrapper'));
    // }
    this.isMenuLoading = true;
    this.$store.dispatch('getMyRoleAll').then(({ role }) => {
      const { role_permissions } = role;
      this.menuList = this.menuList.filter((v) => {
        const page = v.to.replace('/', '').split('/')[0];
        const p = role_permissions.find((rp) => rp.page_name === page || rp.page_name === '*');
        return p && p.can_read;
      });
      this.isMenuLoading = false;
    }).catch(() => {
      this.isMenuLoading = false;
    });
  },
  computed: {
    isLargeScreen: () => window.document.body.clientWidth > 991,
  },
  watch: {
    isLargeScreen() {
      // this.setSidebar();
    },
  },
  methods: {
  },
  components: {
    // SidebarCategory: () => import('./SidebarCategory.vue'),
    SidebarItem: () => import('./SidebarItem.vue'),
  },
};
</script>
<style>
.navbar-dark .navbar-nav .nav-link.active {
  color: #FF8F15 !important;
}
</style>